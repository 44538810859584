import cn from 'classnames';
import { CSSProperties } from 'react';
import { Button } from '@components';
import { Filter } from '@components/icons';
import { useFilterContext } from '@context';
import styles from './FilterButton.module.scss';
interface FilterButtonProps {
	color: 'green' | 'blue' | 'white' | 'transparent-light' | 'transparent-dark' | 'blue-outline';
	isFilterOutline?: boolean;
	onClick?: (value: boolean) => void;
	open?: boolean;
	filtered?: boolean;
	style?: CSSProperties;
}

const FilterButton = ({ color, isFilterOutline = false, onClick, open, filtered, style }: FilterButtonProps) => {
	const { setIsOpen, isOpen, type } = useFilterContext() || {};
	const isBFAllTops = type === 'bf-all-tops';
	const copy = `${!!isOpen || !!open ? 'Hide' : 'Show'} Filters`;
	const filteredCopy = 'Filters Applied';
	return (
		<div
			data-filter-button={type}
			className={cn(styles.filtersButton, {
				[styles.filtersButtonBfTops]: isBFAllTops,
				[styles.filtersButtonIsFilterOutline]: isFilterOutline,
			})}
			style={style}
		>
			<Button
				color={color}
				data-open-filter={!!isOpen}
				onClick={() => {
					setIsOpen?.(!isOpen);
					onClick?.(!open);
				}}
				fullWidth
			>
				<Filter color='currentColor' style={{ maxWidth: 'fit-content' }} />
				<span>{filtered ? filteredCopy : copy}</span>
			</Button>
		</div>
	);
};

export default FilterButton;
