import { forwardRef } from 'react';
import cn from 'classnames';
import { Bag, Caption, Flex } from '@components';
import styles from './ProductViewAllTops.module.scss';

interface TriggerButtonProps {
	hasTops: boolean;
	isFilterOutline: boolean;
	isAlwaysOpen: boolean;
	isQuickAdd: boolean;
	isMobileTablet: boolean;
	badge: number;
	onClick?: () => void;
	isChooseTopsHover?: boolean;
}

const TriggerButton = forwardRef<HTMLDivElement, TriggerButtonProps>(
	({ hasTops, isAlwaysOpen, isFilterOutline, isMobileTablet, isQuickAdd, badge, onClick, isChooseTopsHover }, ref) => (
		<Flex ref={ref} className={styles['shopping-bag-container']} onClick={onClick}>
			<Flex
				className={cn(styles['shopping-bag-trigger'], {
					[styles['is-active']]: hasTops,
					[styles['shopping-bag-trigger--is-filter-outline']]: isFilterOutline || isAlwaysOpen || isChooseTopsHover,
				})}
				align='center'
				justify='center'
			>
				<Bag />
			</Flex>
			<Flex
				align='center'
				justify='center'
				className={cn(styles['mobile-count'], {
					[styles['is-active']]: hasTops,
					[styles['mobile-count--is-filter-outline']]: (isFilterOutline || isAlwaysOpen) && !isMobileTablet,
					[styles['mobile-count--is-filter-outline-quick-add']]: (isFilterOutline || isAlwaysOpen) && isQuickAdd,
				})}
			>
				<Caption style={{ fontWeight: 700, fontSize: '1.4rem', position: 'relative' }}>{badge}</Caption>
			</Flex>
		</Flex>
	)
);

TriggerButton.displayName = 'TriggerButton';

export default TriggerButton;
