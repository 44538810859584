import { memo, ReactElement, useCallback } from 'react';
import dynamic from 'next/dynamic';
import { CartNavButton } from '@components/cart';
import { NormalizedVariant } from '@ts/index';
import variables from '@styles/export.module.scss';
import { Caption, FilterButton, Heading, Flex, Modal, TopFrameEducation } from '@components';
import TopFrameContent from './TopFrameContent';
import styles from './ProductViewAllTops.module.scss';

const Microcart = dynamic(() => import('@components').then(mod => mod.MicrocartQuickAdd), {
	loading: () => <CartNavButton href='/cart' />,
});
interface MobileTitleProps {
	triggerButton: ReactElement;
	tops: {
		[key: string]: NormalizedVariant;
	};
	onTrashClick: (top: NormalizedVariant) => void;
	isQuickAdd: boolean;
	modalOpen: boolean;
	handleModalChange: (shouldOpen: boolean) => void;
	enableFilter?: boolean;
	titleProductView: string;
	isMobile: boolean;
	topFrameTitle: string;
	closeLabel: string;
	conditionToshow: boolean;
	filtered: boolean;
	isFilterOutline: boolean;
	isAlwaysOpen: boolean;
	results: number;
	isMobileLarge: boolean;
}
const MobileView = ({
	isQuickAdd,
	triggerButton,
	tops,
	onTrashClick,
	modalOpen,
	handleModalChange,
	enableFilter,
	titleProductView,
	isMobile,
	topFrameTitle,
	closeLabel,
	conditionToshow,
	filtered,
	isFilterOutline,
	isAlwaysOpen,
	results,
	isMobileLarge,
}: MobileTitleProps) => {
	const hasTops = Object.keys(tops).length !== 0;
	const Results = useCallback(
		() => (
			<Caption style={{ color: variables.gray4 }} data-testid='tops-counter' small={isMobileLarge}>
				{results} items found
			</Caption>
		),
		[isMobileLarge]
	);
	const Header = useCallback(
		() => (
			<Heading tag='h6' style={{ marginTop: '0' }}>
				{titleProductView}
			</Heading>
		),
		[titleProductView]
	);
	const Button = useCallback(
		() =>
			isQuickAdd ? (
				<Microcart triggerButton={triggerButton} tops={tops} onTrashClick={onTrashClick} />
			) : (
				<Modal open={modalOpen} onOpenChange={handleModalChange}>
					<Modal.Trigger>{triggerButton}</Modal.Trigger>
					<Modal.Content className={styles.modalContent}>
						<TopFrameContent
							hasTops={hasTops}
							tops={tops}
							isMobile={isMobile}
							title={topFrameTitle}
							closeLabel={closeLabel}
							onTrashClick={onTrashClick}
						/>
					</Modal.Content>
				</Modal>
			),
		[
			isQuickAdd,
			modalOpen,
			handleModalChange,
			triggerButton,
			tops,
			onTrashClick,
			hasTops,
			topFrameTitle,
			closeLabel,
			isMobile,
		]
	);

	return (
		conditionToshow && (
			<Flex column fullWidth align='center' className={styles['no-preview']}>
				{!enableFilter ? (
					<>
						<Flex fullWidth justify='center' className={styles['preview-header']}>
							<Header />
							<Button />
						</Flex>
						<TopFrameEducation isMobile />
					</>
				) : (
					<Flex fullWidth justify='between'>
						<Flex align='center' justify='center' gap={3}>
							<Header />
							<TopFrameEducation isMobile disableTitle />
						</Flex>
						<Button />
					</Flex>
				)}
				{enableFilter && (
					<FilterButton
						color='blue-outline'
						isFilterOutline
						filtered={filtered}
						style={{
							padding: 0,
							...(!(isAlwaysOpen || isFilterOutline) && { paddingBottom: '0.8rem' }),
						}}
					/>
				)}
				{(isFilterOutline || isAlwaysOpen) && (
					<Flex align='center' justify='center' fullWidth style={{ paddingBlock: '1rem' }}>
						<Results />
					</Flex>
				)}
			</Flex>
		)
	);
};
export default memo(MobileView);
