import { memo, ReactElement } from 'react';
import { Drawer, Flex, Modal, CartActions } from '@components';
import { NormalizedVariant } from '@ts/product';
import styles from './ProductViewAllTops.module.scss';
import TopFrameContent from './TopFrameContent';
interface MainDrawerProps {
	triggerButton: ReactElement;
	showProductViewActions: boolean;
	isQuickAdd: boolean;
	modalOpen: boolean;
	handleModalChange: (shouldOpen: boolean) => void;
	isMobile: boolean;
	tops: {
		[key: string]: NormalizedVariant;
	};
	topFrameTitle: string;
	closeLabel: string;
	onTrashClick: (top: NormalizedVariant) => void;
}
const TopsDrawer = ({
	triggerButton,
	showProductViewActions,
	isQuickAdd,
	modalOpen,
	handleModalChange,
	isMobile,
	tops,
	topFrameTitle,
	closeLabel,
	onTrashClick,
}: MainDrawerProps) => {
	const hasTops = Object.keys(tops).length !== 0;
	return isMobile ? (
		<Modal open={modalOpen} onOpenChange={handleModalChange}>
			<Modal.Trigger>{triggerButton}</Modal.Trigger>
			<Modal.Content className={styles.modalContent}>
				<TopFrameContent
					hasTops={hasTops}
					tops={tops}
					isMobile={isMobile}
					title={topFrameTitle}
					closeLabel={closeLabel}
					onTrashClick={onTrashClick}
				/>
			</Modal.Content>
		</Modal>
	) : (
		showProductViewActions && !isQuickAdd && (
			<Flex>
				<Drawer>
					<Drawer.Trigger asChild>{triggerButton}</Drawer.Trigger>
					<Drawer.Content blurOverlay={false} grayBackground>
						<div>
							<TopFrameContent
								hasTops={hasTops}
								tops={tops}
								isMobile={isMobile}
								title={topFrameTitle}
								closeLabel={closeLabel}
								onTrashClick={onTrashClick}
							/>
							<div className={styles['bf-drawer-cart-actions']}>
								<CartActions />
							</div>
						</div>
					</Drawer.Content>
				</Drawer>
			</Flex>
		)
	);
};

export default memo(TopsDrawer);
