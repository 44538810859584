import cn from 'classnames';
import { Button, Flex, Heading, InformationIcon, Modal, Paragraph } from '@components';
import { slideUp } from '@utils/motions';
import styles from './TopFrameEducation.module.scss';
import { useTranslation } from '@utils/index';

type TopFrameEducationProps = {
	classes?: Partial<{ trigger?: string; content?: string }>;
	isMobile?: boolean;
	disableTitle?: boolean;
};

const getTranslatedTexts = translator => {
	return {
		topFramesQuestion: translator('top-frames-question'),
		topFramesDescription: translator('top-frames-question-answer'),
		topFramesAttachQuestion: translator('top-frames-attach-question'),
		topFramesAttachExplanation: translator('top-frames-attach-explanation'),
		topFramesFabricationQuestion: translator('top-frames-fabrication-question'),
		topFramesFabrication: translator('top-frames-fabrication'),
		topFramesThicknessQuestion: translator('top-frames-thickness-question'),
		topFramesThickness: translator('top-frames-thickness'),
		topFramesWeightQuestion: translator('top-frames-weight-question'),
		topFramesWeight: translator('top-frames-weight'),
		close: translator('close-uppercase'),
	};
};

const TopFrameEducation = ({
	classes = { trigger: null, content: null },
	isMobile,
	disableTitle = false,
}: TopFrameEducationProps) => {
	// TODO - The text are hard code for now, will migrate to contentful later on
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	return (
		<Modal>
			<Modal.Trigger asChild>
				<Flex
					justify='center'
					className={cn({ [classes.trigger]: classes.trigger, [styles.button]: !disableTitle })}
					data-top-frame-education
				>
					<Button linkStyle size='small'>
						<InformationIcon width={isMobile && 16} height={isMobile && 16} />
						{!disableTitle && translations.topFramesQuestion}
					</Button>
				</Flex>
			</Modal.Trigger>
			<Modal.Content className={styles.modal} customAnimation={slideUp}>
				<div>
					<div>
						<Heading tag='h5'>{translations.topFramesQuestion}</Heading>
						<Paragraph>{translations.topFramesDescription}</Paragraph>
					</div>
					<div className={styles.sectionContainer}>
						<Heading tag='h6'>{translations.topFramesAttachQuestion}</Heading>
						<Paragraph>{translations.topFramesAttachExplanation}</Paragraph>
					</div>
					<div className={styles.sectionContainer}>
						<Heading tag='h6'>{translations.topFramesFabricationQuestion}</Heading>
						<Paragraph>{translations.topFramesFabrication}</Paragraph>
					</div>
					<div className={styles.sectionContainer}>
						<Heading tag='h6'>{translations.topFramesThicknessQuestion}</Heading>
						<Paragraph>{translations.topFramesThickness}</Paragraph>
					</div>
					<div className={styles.sectionContainer}>
						<Heading tag='h6'>{translations.topFramesWeightQuestion}</Heading>
						<Paragraph>{translations.topFramesWeight}</Paragraph>
					</div>
				</div>
				<Modal.Close asChild>
					<div style={{ width: '100%', padding: '2.4rem 0 1.6rem' }}>
						<Button color='white' style={{ width: '100%' }}>
							{translations.close}
						</Button>
					</div>
				</Modal.Close>
			</Modal.Content>
		</Modal>
	);
};

export default TopFrameEducation;
