/* eslint-disable max-lines */
import { memo, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useBFContext } from '@context';
import { BUNDLE_KEY_QUERY } from '@utils/constants/cart';
import {
	BUILD_FLOW_STEP_DIRECTION,
	BUILD_FLOW_STEPS,
	CUSTOMER_JOURNEYS,
	getBuildFlowBackTexts,
	getBuildFlowNextTexts,
	getToastMessage,
	LARGE_WIDTH,
	SUBMISSION_METHODS,
} from '@constants';
import { Button, Subtotal } from '@components';
import { useActiveFrame, useBaseFrameCartManager, useTranslation, useWindowDimensions } from '@utils/hooks';
import { useToastContext } from '@utils/context';
import { RX_TYPE } from '@utils/constants/base-skus';
import styles from './CartActions.module.scss';

const getTranslatedTexts = translator => {
	return {
		makeSelection: translator('make-selection'),
	};
};

const CartActions = ({ className = '' }: { className?: string }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setActiveFrame] = useActiveFrame();
	const { query } = useRouter();
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');
	const isBF2AllTops = useFeatureIsOn('bf-2-all-tops');
	const { width: windowWidth } = useWindowDimensions();
	const { showToast } = useToastContext();
	const isEditMode = Boolean(query?.edit);
	const demo = query.demo as string;
	const { translator, locale } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const buildFlowNextText = getBuildFlowNextTexts(locale);
	const buildFlowBackText = getBuildFlowBackTexts(locale);

	// Services
	const {
		step,
		setStepChange,
		frameColor,
		frame,
		journey,
		lensColor,
		rxType,
		readerRx,
		product,
		tops,
		subtotal,
		setStep,
		BaseFrameVariant,
		setLensPackage,
		lensPackage,
		setSelectedLenses,
		setRxType,
		setReaderRx,
		isSubscriptionActivated,
		subscriptionProduct,
		sellingPlanId,
		skipSubscription,
		setSkipSubscription,
		previousBaseFrames,
		isQuickAdd,
	} = useBFContext();

	const isSunglasses = journey === CUSTOMER_JOURNEYS.SUNGLASSES;

	// Data Tags and Step Info
	const isCollectionsStep = step === BUILD_FLOW_STEPS.TOP_FRAMES;
	const isPrescriptionsStep = step === BUILD_FLOW_STEPS.LENS;
	const isPackageStep = step === BUILD_FLOW_STEPS.PACKAGE;
	const isCustomizeStep = step === BUILD_FLOW_STEPS.CUSTOMIZE;
	const isSubmittedStep = step === BUILD_FLOW_STEPS.SUBMIT;

	const isLensExpansionForm = (isCustomizeStep || isPrescriptionsStep || step === BUILD_FLOW_STEPS.PACKAGE) && !isSunglasses;

	const backDataTag = isPrescriptionsStep ? { 'data-exit-buildflow': true } : { 'data-back': true };
	const stepDataTag = isCollectionsStep && { 'data-add-to-cart': 'build-flow' };
	const isReaderStrengthRequired = (isSunglasses ? isPackageStep : isCustomizeStep) && !readerRx && rxType === RX_TYPE.READERS;
	const defaultNextText = !isSunglasses ? buildFlowNextText[BUILD_FLOW_STEPS.LENS] : translations.makeSelection;
	let isNextDisabled =
		isSubmittedStep ||
		!rxType ||
		(Object.keys(tops).length === 0 && isCollectionsStep && isSubscriptionActivated && isV2Subscription);

	if (!isSunglasses) isNextDisabled = isNextDisabled || !lensPackage;
	const shouldHideChevron =
		isCollectionsStep && isV2Subscription && isNextDisabled
			? false
			: !(isSubmittedStep || isEditMode) && !(windowWidth < 1115 && windowWidth >= LARGE_WIDTH);

	const ATC = useBaseFrameCartManager({
		editingBundleKey: (query[BUNDLE_KEY_QUERY] as string) ?? null,
		demo,
		frame,
		frameColor,
		journey,
		lensColor,
		rxType,
		readerRx,
		product,
		tops,
		submissionMethod: SUBMISSION_METHODS.REMIND,
		BaseFrameVariant,
		lensPackage,
		subscriptionVariant: !isSubscriptionActivated || skipSubscription ? null : subscriptionProduct,
		sellingPlanId: sellingPlanId,
		isSubscriptionActivated,
		isBuildFlow: true,
	});

	useEffect(() => {
		if (step !== BUILD_FLOW_STEPS.SUBMIT) return;
		ATC();
	}, [step, isBF2AllTops]);

	function handleStepNavigation({ type, currentStep = step }) {
		const isSunglasses = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
		if (type === 'SUBMIT') {
			setStep(BUILD_FLOW_STEPS.SUBMIT);
			setActiveFrame(frame);
		}
		if (type === 'NEXT') {
			// This moves the BF steps to go to TOP FRAMES is the user has selected a lens package and rx type
			if (!!rxType && !!lensPackage && isLensExpansionForm && !isSunglasses) {
				setStep(BUILD_FLOW_STEPS.TOP_FRAMES);
				return;
			}
			if (isSunglasses) {
				if (rxType === RX_TYPE.READERS) {
				} else {
					setStep(BUILD_FLOW_STEPS.TOP_FRAMES);
					return;
				}
			}
			// since reader already preselected "standard" as lens package, we can skip the lens step
			if (rxType === RX_TYPE.READERS) {
				setStep(BUILD_FLOW_STEPS.TOP_FRAMES);
				return;
			}

			setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT);
		}
		if (type === 'CLOSE' || type === 'PREVIOUS') {
			if (currentStep === BUILD_FLOW_STEPS.TOP_FRAMES) {
				setSkipSubscription(false);
			}
			if (!isSunglasses) {
				if (isCustomizeStep) {
					setSelectedLenses([]);
					setLensPackage(null);

					if (rxType === RX_TYPE.READERS) {
						setStep(BUILD_FLOW_STEPS.LENS);
						setReaderRx(null);
						return;
					}
				}

				if (step === BUILD_FLOW_STEPS.PACKAGE && !isEditMode) {
					setRxType(null);
				}
			}

			setStepChange(BUILD_FLOW_STEP_DIRECTION.PREV);
		}
	}

	function getBackButtonText(currentStep) {
		if (currentStep <= BUILD_FLOW_STEPS.LENS) {
			return buildFlowBackText.EXIT;
		}

		return buildFlowBackText.BACK;
	}

	function getNextButtonDataTag(currentStep: BUILD_FLOW_STEPS) {
		if (journey !== CUSTOMER_JOURNEYS.EYEGLASSES && isSunglasses && currentStep === BUILD_FLOW_STEPS.LENS) {
			return BUILD_FLOW_STEPS[BUILD_FLOW_STEPS.TOP_FRAMES];
		}

		return BUILD_FLOW_STEPS[currentStep + 1];
	}

	const getNextButtonText = useCallback(
		(currentStep, isEditing) => {
			const rules = {
				EDIT_MODE:
					isEditing && (currentStep === BUILD_FLOW_STEPS.TOP_FRAMES || (isBF2AllTops && !isSubscriptionActivated)),
				BF_2_ALL_TOPS: isBF2AllTops && !isSubscriptionActivated,
				CUSTOMIZE_OR_SUBSCRIPTION:
					(journey === CUSTOMER_JOURNEYS.SUNGLASSES && currentStep === BUILD_FLOW_STEPS.LENS) ||
					(!isSunglasses && rxType && lensPackage && isLensExpansionForm),
				ONE_MORE_TOP: currentStep === BUILD_FLOW_STEPS.TOP_FRAMES && isNextDisabled && isV2Subscription,
				QUICK_ADD_CONTINUE: isQuickAdd,
				QUICK_ADD_WITH_TOPS: isQuickAdd && Object.keys(tops).length > 0,
			};

			if (rules.QUICK_ADD_WITH_TOPS) return buildFlowNextText.QUICK_ADD_PLUS_CONTINUE;
			if (rules.QUICK_ADD_CONTINUE) return buildFlowNextText.QUICK_ADD_CONTINUE;
			if (rules.EDIT_MODE) return buildFlowNextText.EDIT_MODE;
			if (rules.BF_2_ALL_TOPS) return buildFlowNextText.BF_2_ALL_TOPS;
			if (rules.ONE_MORE_TOP) return buildFlowNextText.ONE_MORE_TOP;

			return buildFlowNextText[currentStep];
		},
		[
			isBF2AllTops,
			isSubscriptionActivated,
			journey,
			isSunglasses,
			rxType,
			lensPackage,
			isLensExpansionForm,
			isV2Subscription,
			isNextDisabled,
			isQuickAdd,
			tops,
		]
	);

	return (
		<>
			<div className={cn(styles['cart-actions'], className)}>
				<Subtotal subtotal={subtotal} />
				<div className={styles['button-container']}>
					<Button
						size='small'
						color='white'
						extraClasses={styles['prev']}
						disabled={isSubmittedStep}
						withChevron={true}
						chevronDirection='left'
						chevronPosition='front'
						label={getBackButtonText(step)}
						onClick={() =>
							handleStepNavigation({ type: isPrescriptionsStep ? 'CLOSE' : 'PREVIOUS', currentStep: step })
						}
						{...backDataTag}
					/>
					<Button
						size='small'
						color='green'
						fullWidth
						extraClasses={`${styles['next']}`}
						disabled={isNextDisabled}
						withChevron={shouldHideChevron}
						chevronDirection='right'
						label={rxType ? getNextButtonText(step, isEditMode) : defaultNextText}
						onClick={() =>
							isReaderStrengthRequired
								? showToast(getToastMessage(locale).READERS_STRENGTH_ERROR)
								: handleStepNavigation({
										type:
											isCollectionsStep ||
											(isBF2AllTops &&
												!isSubscriptionActivated &&
												(isCustomizeStep || isEditMode || previousBaseFrames.length > 0)) ||
											(isBF2AllTops && isSunglasses)
												? 'SUBMIT'
												: 'NEXT',
									})
						}
						style={{ justifyContent: 'center' }}
						data-go-to-step={getNextButtonDataTag(step)}
						{...stepDataTag}
					/>
				</div>
			</div>
		</>
	);
};

CartActions.displayName = 'CartActions';

export default memo(CartActions);
