import cn from 'classnames';
import { memo } from 'react';
import { NormalizedVariant } from '@ts/product';
import { Caption, Close, Drawer, Flex, LabelText, MyTops } from '@components';
import styles from './ProductViewAllTops.module.scss';
interface TopFrameContentProps {
	hasTops: boolean;
	tops: {
		[key: string]: NormalizedVariant;
	};
	isMobile: boolean;
	title: string;
	closeLabel: string;
	onTrashClick: (top: NormalizedVariant) => void;
}
const TopFrameContent = ({ title, tops, isMobile, hasTops, closeLabel, onTrashClick }: TopFrameContentProps) => (
	<>
		<Flex gap={3} align='center' justify='start' className={styles.header} fullWidth>
			<Flex gap={3} align='center'>
				<Caption>{title}</Caption>
				{hasTops && (
					<Flex
						align='center'
						justify='center'
						className={cn(styles['tops-counter'], { [styles['is-active']]: hasTops })}
					>
						<LabelText style={{ fontWeight: 700, fontSize: '1.4rem' }}>{Object.keys(tops).length}</LabelText>
					</Flex>
				)}
			</Flex>
			{!isMobile && (
				<Drawer.Close asChild>
					<Close wrapperClass={styles['close-button']} height={13} width={13} label={closeLabel} />
				</Drawer.Close>
			)}
		</Flex>
		<Flex column align='center' fullWidth className={styles.drawerContent}>
			<MyTops tops={tops} onTrashClick={onTrashClick} />
		</Flex>
	</>
);
export default memo(TopFrameContent);
